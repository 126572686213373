import React, {useState, useEffect} from 'react'
import { Input, Tooltip, Popover, Radio, Modal, message } from 'antd';
import {
  ArrowDownOutlined,
  DownOutlined,
  SettingOutlined,
} from "@ant-design/icons";
import tokenList from "../1inchTokenList.json";
import axios from "axios";
import { useSendTransaction, useWaitForTransaction } from 'wagmi';
const qs = require('qs');

function Swap(props) {
  const {address, isConnected, chain} = props;
  console.log("address isConn chain from props:", address, isConnected, chain);
  const [messageApi, contextHolder] = message.useMessage();
  const [slippage, setSlippage] = useState(2.5);
  const [gasFee, setGasFee] = useState(null);
  const [liquiditySource, setLiquiditySource] = useState(null);
  const [tokenOneAmount, setTokenOneAmount] = useState(null);
  const [tokenTwoAmount, setTokenTwoAmount] = useState(null);
  const [tokenOne, setTokenOne] = useState(tokenList[0]);
  const [tokenTwo, setTokenTwo] = useState(tokenList[1]);
  const [isOpen, setIsOpen] = useState(false);
  const [changeToken, setChangeToken] = useState(1);
  const [prices, setPrices]  = useState(null);
  const [swapButton, setSwapButton] = useState('Swap')
  const [searchInput, setSearchInput] = useState('');
  const [filteredResults, setFilteredResults] = useState([]);
  const [txDetails, setTxDetails] = useState({
    to: null,
    data: null,
    value: null,
  });

  const {data, sendTransaction} = useSendTransaction({
    request: {
      from: address,
      to: String(txDetails.to),
      data: String(txDetails.data),
      value: String(txDetails.value),
    }
  })

  const { isLoading, isSuccess } = useWaitForTransaction({
    hash: data?.hash,
  })

  function handleSlippageChange(e){
    setSlippage(e.target.value);
  }
  
  function clearAll () {
    setTokenTwoAmount(null);
    setGasFee(null);
    setLiquiditySource(null);

  }

  const searchItems = (searchValue) => {

    setSearchInput(searchValue)
    console.log('search value:', searchValue);
    if (searchInput !== '') {
    const filteredData = tokenList.filter((item) => {
      return Object.values(item).join('').toLowerCase().includes(searchInput.toLowerCase())
      })
      setFilteredResults(filteredData)
    } else {
      setFilteredResults(tokenList)
    }
  
  }

  function changeAmount(e) {
    setTokenOneAmount(e.target.value);
    if(e.target.value && prices){
      setTokenTwoAmount((e.target.value * prices.ratio).toFixed(4))
      setGasFee((prices.gasDollars))
      setLiquiditySource(prices.liquiditySource)
    } 
    else {
      clearAll();
    }
  }

  function switchTokens(){
    setPrices(null);
    clearAll();
    setGasFee(null);
    setLiquiditySource(null);
    setTokenOneAmount(null);
    setTokenTwoAmount(null);
    const one = tokenOne;
    const two = tokenTwo;
    setTokenOne(two);
    setTokenTwo(one);
    fetchPrices(two.address, one.address);
  }

  function openModal(asset){
    setChangeToken(asset);
    setSearchInput('');
    setIsOpen(true);
  }

  function modifyToken(i){
    setPrices(null);
    clearAll();
    setTokenOneAmount(null);
    setTokenTwoAmount(null);
    if (changeToken === 1){
      setTokenOne(tokenList[i]);
      fetchPrices(tokenList[i].address, tokenTwo.address)
    } else {
      setTokenTwo(tokenList[i]);
      fetchPrices(tokenOne.address, tokenList[i].address)
    }
    setIsOpen(false);
  }

  async function findAndModifyToken(e){
//Look up token in full TokenList array so you get the original index/ token position
    console.log("ticker =", e.ticker);
    var i = tokenList.findIndex(function(element) {
        return element.ticker === e.ticker;
    })
        console.log('position:', i)
        modifyToken(i)
      };

      async function fetchPrices(one, two){
        const params = {
          sellToken: one, 
          buyToken: two, 
          sellAmount: 100000000000000000
        }
      try {
// later keep all keys in .env file
    const headers = {'0x-api-key': '558af1ec-9db0-4d3c-94e7-fcb54025d63b'};
    const res = await axios.get(
    `https://api.0x.org/swap/v1/price?${qs.stringify(params)}`,{ headers })

  if(res.status === 200){
      // test for status you want, etc
      console.log("status:", res.status);
      console.log("chain:", chain);
      console.log("address:", address);
  }   
  // Don't forget to return something   
    console.log(res.data)
    const usdPrices = {
      tokenOne: res.data.sellTokenToEthRate,
      tokenTwo: res.data.buyTokenToEthRate,
      ratio: res.data.buyTokenToEthRate/res.data.sellTokenToEthRate,
      gasEstimate: res.data.gasPrice,
      gasDollars: String((res.data.gasPrice / 1e9).toFixed(2)),
      liquiditySource: res.data.sources.filter(result=>result.proportion > 0).map(ele=>ele.name),

    }
    console.log(usdPrices)
    setPrices(usdPrices);

    return 
  }
  catch (err) {
    messageApi.destroy();
    messageApi.open({
      type: 'error',
      content: 'Sorry, currently this token pair is not available to trade.',
      duration: 2,
    })
    console.error(err);
  }
} 

/*useEffect(() => {
//fetchPrices(tokenOne.address, tokenTwo.address);
  const interval = setInterval(() => {
  fetchPrices(tokenOne.address, tokenTwo.address);
  console.log('Interval triggered');
}, 90000);
return () => clearInterval(interval);
}, []);
*/

  async function fetchDexSwap(){
    const allowance = await axios.get(`https://api.1inch.io/v5.0/1/approve/allowance?tokenAddress=${tokenOne.address}&walletAddress=${address}`)
    console.log('Allowance:', allowance.data.allowance);

    if(allowance.data.allowance === "0") {
      console.log("going for approval ...");
      const approve = await axios.get(`https://api.1inch.io/v5.0/1/approve/transaction?tokenAddress=${tokenOne.address}`)
      setTxDetails(approve.data);
      
      return
    }

    console.log("going to make swap ...")
    const tx = await axios.get(
      `https://api.1inch.io/v5.0/1/swap?fromTokenAddress=${tokenOne.address}&toTokenAddress=${tokenTwo.address}&amount=${tokenOneAmount.padEnd(tokenOne.decimals+tokenOneAmount.length, '0')}&fromAddress=${address}&slippage=${slippage}&fee=1.5&referrerAddress=0x1664403e7BA864139f53d50fBbc45750C7eB7941`
    )

    let decimals = Number(`1E${tokenTwo.decimals}`)
    setTokenTwoAmount((Number(tx.data.toTokenAmount)/decimals).toFixed(4));
    
    setTxDetails(tx.data.tx);
    console.log('Tx data:', tx.data.tx) 
    console.log("tx.statusCode:", tx.statusCode) 
//    if(tx.data.tx.statusCode !== 200){
    if(tx.statusCode !== 400){
      console.log("Transaction Sent:", tx.description)
      messageApi.destroy();
      messageApi.open({
        type: 'info',
        content: 'Transaction Sent. Waiting network confirmation...',
        duration: 2.5,})
    
    } else {
      console.log("Transaction Failed:", tx.description)
      messageApi.destroy();
      messageApi.open({
        type: 'error',
        content: 'Transaction Failed.',
        duration: 2.5,})
      

    }
    clearAll();
    setPrices(null);
    setTokenOneAmount(null);
  }
 
  useEffect(() => {
    fetchPrices(tokenList[0].address, tokenList[1].address)
  }  , [])
  
// todo: swap button to change "connect wallet", "swap", "Approve in wallet" etc.
//  useEffect(()=>{
//    if (isConnected){
//    setSwapButton("Swap")
//    } else {
//    setSwapButton("Connect Wallet")
//    }
//  }, [])

  useEffect(()=>{

    if(txDetails.to && isConnected){
      sendTransaction();
    }
  }, [txDetails])
    
  useEffect(()=>{

    messageApi.destroy();

    if(isLoading){
      messageApi.open({
        type: 'loading',
        content: 'Transaction is Pending for Network Confirmation ...',
        duration: 0,
      })
    }    

  },[isLoading])

  useEffect(()=>{
    messageApi.destroy();
    if(isSuccess){
      messageApi.open({
        type: 'success',
        content: 'Transaction Successful',
        duration: 2.5,
      })
    }else if(txDetails.to){
      messageApi.open({
        type: 'error',
        content: 'Transaction Failed',
        duration: 2.50,
      })
    }


  },[isSuccess])

  useEffect(()=>{
    messageApi.destroy();
    if(!prices){
      messageApi.open({
        type: 'loading',
        content: 'Please wait ...',
        duration: 0,
      })
    }
  })

  const settings = (
    <>
      <div>Slippage Tolerance</div>
      <div>
        <Radio.Group value={slippage} onChange={handleSlippageChange}>
          <Radio.Button value={0.5}>0.5%</Radio.Button>
          <Radio.Button value={2.5}>2.5%</Radio.Button>
          <Radio.Button value={5}>5.0%</Radio.Button>
        </Radio.Group>
      </div>
    </>
  );
  return (
  <>
    {contextHolder}
    
    <Modal
      open={isOpen}
      footer={null}
      onCancel={() => setIsOpen(false)}
      title="Select a token"
    >
    <div className='modalContent'>
      <Input icon='search' value={searchInput}
                placeholder='Search a token...'
                onChange={(e) => searchItems(e.target.value)}
            />
      {searchInput.length > 0 ? (
          filteredResults?.map((e, i) => {
            return (
              <div
              className='tokenChoice'
//              key={i}  alert(e.ticker)
              onClick={() => findAndModifyToken(e) }
              >
          <img src={e.img} alt={e.ticker} className="tokenLogo" />
          <div className="tokenChoiceNames">
            <div className='tokenName'>{e.name}</div>
            <div className='tokenTicker'>{e.ticker}</div>
          </div>
        </div>
          );
            })
      ) : (
        tokenList?.map((e, i) => {
        return (
          <div
            className='tokenChoice'
            key={i}
            onClick={() => modifyToken(i)}
      >
        <img src={e.img} alt={e.ticker} className="tokenLogo" />
        <div className="tokenChoiceNames">
          <div className='tokenName'>{e.name}</div>
          <div className='tokenTicker'>{e.ticker}</div>
        </div>
      </div>
        );
      })
      )
    }
    </div>
    </Modal>  
    <div className='tradeBox'>
      <div className='tradeBoxHeader'  >
        <h4>Swap Your Tokens</h4>
        <Tooltip placement="topRight" title="Settings">
        <Popover
          content={settings}
          title="Settings"
          trigger="click"
          placement='bottomRight'>
        <SettingOutlined className='cog' />
        </Popover>
        </Tooltip>
      </div>
      <div className='inputs'>
        <Input placeholder='0' value={tokenOneAmount} 
        style={{ textAlign: "right" }} onChange={changeAmount} disabled={!prices} />
        <Input placeholder='0' value={tokenTwoAmount} 
        style={{ textAlign: "right" }} disabled={true} /> 
        <div className='switchButton' onClick={switchTokens}>
          <ArrowDownOutlined className='switchArrow' />
        </div>
        <Tooltip placement="topRight" title="Select the Token you want to Swap/ or Pay With (or Sell)">
        <div className='assetOne' onClick={() => openModal(1)}>
          <img src={tokenOne.img} alt='assetOneLogo' className='assetLogo' />
          {tokenOne.ticker}
          <DownOutlined />
        </div>
        </Tooltip>
        <Tooltip placement="topRight" title="Select the Token you want to Swap to/ or Buy">
        <div className='assetTwo' onClick={() => openModal(2)}>
        <img src={tokenTwo.img} alt='assetTwoLogo' className='assetLogo' />
          {tokenTwo.ticker}
          <DownOutlined />
        </div>
        </Tooltip>
           
      <Input placeholder='0' value={gasFee} style={{ textAlign: "right" }} disabled={true} /> 
      <Input placeholder='' value={liquiditySource} disabled={true} /> 
      <Tooltip placement="topRight" title="Ethereum network fees">
      <div className='gasFee'>
      Gas Fees: in Gwei
      </div>
      </Tooltip>
      <Tooltip placement="topRight" title={liquiditySource}>
      <div className='liquiditySource'>
      Liquidity Source:
      </div>
      </Tooltip>
      </div>
      <div className='swapButton' disabled={!tokenOneAmount || !isConnected || (chain.id != 1)} onClick={fetchDexSwap} title='Swap'>{swapButton}</div>

    <div>
    {!prices ? (
    <span>Oops! Something went wrong. Please reload the page (F5).</span>
  ) : (
    <div className='tradeMessageBox' >
      <span>Buy Price:  1 {tokenTwo.ticker} = {(1/prices.ratio)} {tokenOne.ticker}</span>
      <span>Sell Price: 1 {tokenOne.ticker} = {prices.ratio} {tokenTwo.ticker}</span>
    </div>
  )}
  </div>
  </div>
  </>  
  );

  }
export default Swap