import React, {useState, useEffect} from 'react';
//import Logo from "../macarons.svg"; 
//that's small image of macaroons
import fullLogo from "../macaroonswap-mini20.png";
import Eth from "../eth.svg";
import Bsc from "../bsc.svg";
import Arb from "../arb.svg";
import Que from "../que.svg.png";
import chains from "../ChainList.json";
import { NavLink } from "react-router-dom";
import { Tooltip, Input, Menu, Select, Dropdown } from 'antd';
//import { DownOutlined} from "@ant-design/icons";
import { Wallet } from 'ethers';
import { useConnect, useAccount, useNetwork } from "wagmi";
import { MetaMaskConnector } from "wagmi/connectors/metaMask";
//import { renderCloseIcon } from 'antd/es/modal/PurePanel';
import Icon from '@ant-design/icons/lib/components/Icon';

function Header({callback}) {
  
//  const {address, isConnected, connect, chain} = props;
  const [chainImage, setChainImage] = useState(Eth);
  const [selectedid, setSelectedId] = useState(1);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const { address, isConnected } = useAccount();
  const { connect } = useConnect({
    connector: new MetaMaskConnector(),
  });
  const { chain } = useNetwork();
  const chainid = null;
// passing callback data to App (since metamask connection used to be on App now moved to Header.)
const data = { chain, address, isConnected };

  try {
  const chainid = chain.id;
//  console.log("try chain id:", chain.id);  
  } catch {console.log("chain null");}

  try {
  console.log("in ChainSelect. chain:", chain);
  console.log("in ChainSelect. chainid:", chainid);
/* will open it later when we have multichains.
  switch (chain.id) {
  case 1: 
  console.log("in Eth");
*/if (chain.id === 1)  {
   var the_div = <div className='chainSelect'>
    <img src="./static/media/eth.df265e367364f285053a1285ad8d418d.svg" alt='Ethereum Network' className='assetLogo' />
    Ethereum Network
    </div> }  else {
  console.log("in catch");
  var the_div =  <div className='chainSelect'>
  <img src="./static/media/que.svg.422e2d652c0c5b6609db.png" alt='No Network' className='assetLogo' />
  <Icon type="question-circle" theme="filled" />
  Select Ethereum Chain in Wallet
  </div>
  }

/*    break
    case 56:
    console.log("in Bnb");
  var the_div =  <div className='chainSelect'>
    <img src="./static/media/bsc.d246cd685ee3f7dffe2b13cb422cdf0d.svg" alt='BNB Network' className='assetLogo' />
    BNB Network
    </div>
    break
  case 42161:
    console.log("in Arb");
  var the_div =  <div className='chainSelect'>
    <img src="./static/media/arb.60b96f878ff1520a5f0624ae9aa7f0c9.svg" alt='ARB Network' className='assetLogo' />
    Arbitrum Network
    </div>
    break
    }
 */
  } catch {console.log("in catch chainid issue");

  console.log("in catch");
  var the_div =  <div className='chainSelect'>
  <img src="./static/media/que.svg.422e2d652c0c5b6609db.png" alt='No Network' className='assetLogo' />
  <Icon type="question-circle" theme="filled" />
  Please Connect Wallet
  </div>
  }

return ( 
    <header>
      <div className='leftH'>
      <img src={fullLogo} alt="Full logo" width="351" height="103" className='fulllogo' />
      </div>
      <div className="leftH">
        <NavLink to="/" className="link" style={({ isActive }) => 
                      (isActive ? {color: 'white'} : {color: 'blue'})}>
          <div className='headerItem'>Swap</div>
        </NavLink>
        <NavLink to="/tokens" className="link" style={({ isActive }) => 
                      (isActive ? {color: 'white'} : {color: 'blue'})}>
          <div className='headerItem'>Tokens</div>
        </NavLink>
        <NavLink to="/howdoi" className="link" style={({ isActive }) => 
                      (isActive ? {color: 'white'} : {color: 'blue'})}>
          <div className='headerItem'> FAQs </div>
        </NavLink>
      </div>
      <div className='rightH'>
      <Tooltip placement="topRight" title="Network Channel">
      <div className='headerItem'>
          {the_div}
          {callback(data)}
      </div>
      </Tooltip>
        <div className='connectButton' onClick={connect}>
          {isConnected && (chain.id === 1) ? (address.slice(0,4) +"..."+address.slice(38)) : "Connect"}
        </div>
      </div>
    </header>
  )

}


export default Header