import "./App.css";
import Header from "./components/Header";
import { Routes, Route } from "react-router-dom";
import Swap from "./components/Swap";
import Tokens from "./components/Tokens";
import HowDoI from "./components/HowDoI";
import {useState, useEffect} from 'react';
//import { useConnect, useAccount, useNetwork } from "wagmi";
//import { MetaMaskConnector } from "wagmi/connectors/metaMask";
// A: I have moved metamask stuff to header..so passing props from there and useState  
// them here so further chain in Swap is not disturbed. (Header -> App -> Swap)

function App() {
  const [chain, setChain] = useState('');
  const [address, setAddress] = useState('');
  const [isConnected, setisConnected] = useState(null);
  
  const callFromHeader = (value) => {
    console.log("header passed data:", value.chain, value.address, value.isConnected);
    setAddress(value.address);
    setChain(value.chain);
    setisConnected(value.isConnected);
  };
  return (
  <div className="App">
    <Header callback={callFromHeader} />
    <div className="mainWindow">
      <Routes>
        <Route path="/" element={<Swap chain={chain} isConnected={isConnected} address={address} />} />
        <Route path="/tokens" element={<Tokens />} />
        <Route path="/howdoi" element={<HowDoI />} />
      </Routes>
    </div>
   </div>
  );
}

export default App;
