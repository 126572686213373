import React from 'react'

function HowDoI() {
  // Fetch all the details element.
const details = document.querySelectorAll("details");

// Add the onclick listeners.
details.forEach((targetDetail) => {
  targetDetail.addEventListener("click", () => {
    // Close all the details that are not targetDetail.
    details.forEach((detail) => {
      if (detail !== targetDetail) {
        detail.removeAttribute("open");
      }
    });
  });
});
  return (
    <div>
    <div><h1>How Do I? (FAQs)</h1></div>
    <div>
  <details>
  <summary>System Requirements</summary>
  <p>
    Macaroon Swap is a Web app and it requires a computer running a Linux/ Mac/ Windows operating system. 
    The internet browser on the computer needs to have Javascript capability. The computer must have at least 4GB
    memory and ideally some kind of long-term storage Hard-drive. An input device like mouse or touch screen as well
    as some form of output device is recommended. We are working on a mobile app on Android and iPhone of the Macaroon Swap
     and soon will let you know when it is launched. Stay tuned.
  </p>
  </details><details>
  <summary>What Admin fees Macaroonswap has to swap any token?</summary>
  <p>
    No fees is taken at MacaroonSwap, So ZERO/ ZILCH/ NADA.
    Currently the user is not charged any admin fees to use our platform. 
    Compare that to most centralized exchanges, like 
    Coinbase who take a fees of 0.35% of the transaction amount over an 
    above the gas/ network fees.
  </p>
  </details><details>
  <summary>How do I connect Wallet?</summary>
  <p>
    Currently the "Metamask" wallet can only be connected with the app. Shortly in future
    we will be adding more wallets to work with the app. You need to have 
    "Metamask" wallet browser extension in your browser.
    To connect the wallet click on the top-right "Connect" Button
    that should open Metamask wallet for your permission to connect. 
  </p>
  </details><details>
  <summary>What is a swap?</summary>
  <p>
    Swap allows you to exchange the crypto coins or tokens that you have in your wallet with
    another crypto token of your choice. Currently the Ethereum chain is supported. We will have other 
    EVM chains and conversion bridges soon. Therefore only tokens
    on Ethereum chain can be swapped with another token on the same chain.
    For example, you may have "Aave" tokens that you can swap with "USDC" tokens. This is 
    same as when you want to sell "Aave" tokens for "USDC" tokens.
    Or same as when you want to buy "USDC" token by paying with "Aave" tokens.
  </p>
  </details><details>
  <summary>How do I swap my tokens?</summary>
  <p>
    Click on Swap menu item. That will open Swap page. Swap allows you to exchange the crypto token that 
    you have in your wallet with another crypto coin or token of your choice. Currently the Ethereum chain is supported 
    (We will have other EVM chains soon.). Therefore only tokens
    on Ethereum chain can be swapped with another token on the same chain. The tokens that are available on this app
    are listed under "Tokens" menu. You can search for your choice token from the list of available tokens by putting the 
    token ticker or token name.
    <b><br />
      As an incentive to users, we have ZERO administrative fees, currently. You will only be paying 'Gas Fees' which is 
      Ethereum network's transaction fees. The transaction fee goes to the Ethereum Node which completes your transaction. 
      This may change in future, any time soon.
      Also we are working on Governance Tokens MAC of our swap, Macaroon-Swap. When we are ready to Air-Drop MAC tokens,
      we will send them to all the users who have done transactions on Macaroon-Swap app. The more amounts you transact, 
      the more Air-Drop MAC tokens you will receive.
    </b>
  </p>
  </details><details>
  <summary>How does a swap work?</summary>
  <p>
    To Swap follow these steps:
    Step 1: Click on the dropdown button to search and select the token that you want to swap. 
    You should be having this token in your Metamask wallet.
    Step 2: Click on the dropdown button in the second box to search and select
    the token that you want to buy/ swap to.
    Step 3: Now enter the amount of tokens that you want to sell/ swap in the first box.
    The app will show you the amount of token that you will receive in the second box. the
    app will also show current Gas Fees (or network fees) for this transaction. You need to have 
    some Ethereum in your wallet to cover the fees for this swap transaction.   
    </p>
    </details><details>
  <summary>What tokens I can swap here? (Or What tokens can I sell/buy?)</summary>
  <p>
  Most Ethereum chain tokens can be swapped or bought/ sold here through this app. The
  complete list can be found at Tokens page by clicking on the top menu "Tokens".
  You can search for the tokens that you may want to Swap or Buy/ Sell on that page.
  Very soon all EVM chains like Binance (BSC), Polygon, Arbitrum, Avalanche, Optimism, Fantom,
  Cronos, Palm etc. will be made available on this swap platform here. Stay tuned.
  </p>
  </details><details>
  <summary>What is Allowance Approval? Why is that required?</summary>
  <p>
  In Ethereum ecosystem, users have to hold ETH on their wallet balance to be able to interact 
  with any smart contract on Ethereum chain. ETH is required to pay gas costs while sending an additional 
  transaction aka approval - a permission to a specific smart contract to spend your token. 
  This issue was addressed in the EIP-2612 and the solution came in the elegant form of "permit" 
  method added to the token structure. Permits allow anyone to authorize and swap a token in one simple 
  transaction.<br />
  If the token that you wish to swap ('You Pay' token) supports the permit method, and you haven't already given 
  an allowance permit to the smart contract, you will see the option "permit and swap" on the Swap button of the UI.
  After signing, the permit will be valid for 30 min - the period given to a user to execute a swap, so proceed with the swap.
  </p>
  </details><details>
  <summary>I hear Macaroon Swap is going to Air Drop their Governance tokens - the MAC tokens?</summary>
  <p>
      Yes, we are actively working on Governance Tokens MAC of Macaroon-Swap. Soon, when we are ready to Air-Drop MAC tokens,
      we will send them to all the users who have done transactions on this swap app, the Macaroon-Swap app. The more amounts you transact, 
      the more Air-Drop MAC tokens you will receive.
  </p>
  </details>
</div>
</div>
  )
}

export default HowDoI