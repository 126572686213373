import React, {useState, useEffect} from 'react'
import { Input } from 'antd';
import tokenList from "../1inchTokenList.json";

function Tokens(props) {
  const Search = Input.Search;
  const [searchInput, setSearchInput] = useState('');
  const [filteredResults, setFilteredResults] = useState([]);

  const clearSearch= () => {
    // clear input value
    setSearchInput('');
  };

  const searchItems = (searchValue) => {
    setSearchInput(searchValue)
//    console.log('search value:', searchValue);
    if (searchInput !== '') {
    const filteredData = tokenList.filter((item) => {
      return (Object.values(item.name).join('').toLowerCase().includes(searchInput.toLowerCase()) || Object.values(item.ticker).join('').toLowerCase().includes(searchInput.toLowerCase()))
      })
      setFilteredResults(filteredData)
      console.log("search:",filteredResults)
    } else {
      setFilteredResults(tokenList)
      console.log("search:", filteredResults)
    }
  
  }
  
  
  return (
<>
    <div></div>
    <div></div>
      <div className='tokenlist'>
      <h1>Current List Of Tokens: </h1>
        <Input icon='search' value={searchInput}
                  placeholder='Search a token...'
                  onChange={(e) => searchItems(e.target.value)}
              />
        {searchInput.length > 0 ? (
            filteredResults?.map((e) => {
              return (
          <div>
            <table><tr><td>
            <img src={e.img} alt={e.ticker} className="tokenLogo" />
            </td>
            <td>{e.ticker}</td>
            <td>{e.name}</td>
            </tr></table>      
          </div>
            );
              })
        ) : (
          tokenList?.map((e) => {
          return (
            <div>
            <table><tr><td>
            <img src={e.img} alt={e.ticker} className="tokenLogo" />
            </td>
            <td>{e.ticker}</td>
            <td>{e.name}</td>
            </tr></table>      
          </div>
          );
        })
        )
      }
      </div>
      <div className='searchClear' onClick={() => clearSearch()}>
      Clear Search
      </div>

</>

      )
  }

export default Tokens